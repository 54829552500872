

















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import './scss/FiltersSelect.scss';
import './scss/FiltersSelectAdaptive.scss';

@Component({
    name: 'FiltersSelect',
    components: {
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
    }
})

export default class FiltersSelect extends Vue {
    @Prop({}) title!: string;
    @Prop({}) options!: any[];

    selected: any[] = [];

    selectOption() {
        const outputEmit: any[] = this.selected.map((option: any) => option?.label || option?.title);
        this.$emit('change-selected', outputEmit);
    }

    resetSelected() {
        this.selected = [];
    }

    changeData(data) {
        if (data.section === this.title) {
            this.selected = data.selected;
        }
    }
}
